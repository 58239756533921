import TruckerPage from 'components/pages/promotional/trucker/TruckerPage';
import { graphql } from 'gatsby';
import * as React from 'react';

const Index = () => (
	<div>
		<TruckerPage />
	</div>
);

export default Index;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
